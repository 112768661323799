<template>

  <div class="actions-head">
    <UiButton                
      :name="'Summary statistics'"
      :type="'link'"
      :ico="'icon-chart'"
      @click="showStatisticPackage = true"
      />
  </div>

  <div v-if="data.role_name" class="data_banners">

      <div v-if="allowTypeBanner('box')">

        <div class="subtitle"> 


          <h2 class="banner_type_name">{{ $t('brand_manager_view_tab_banners_box_banner') }}</h2>
            
          <div class="active-switch">
            <n-switch v-model:value="show_box_active_banners" :checked-value="true" :unchecked-value="false" />
            &nbsp;&nbsp;{{ $t('brand_manager_view_tab_banners_only_active') }}
          </div>

          <UiButton                
            :tag="'NuxtLink'"
            :to="'banners/edit/box/0'"
            :name="$t('brand_manager_view_tab_banners_add_button')"
            />
 
        </div>

        <div style="clear:both">&nbsp;</div>

        <n-table 
          :single-column="false" 
          :single-line="false" 
          class="banners_table" 
          v-if="data_banners?.box_banners?.length"
          >
        
          <thead v-if="data_banners.box_banners.find(b => b.is_enable) || !show_box_active_banners">
            <tr>
              <th>{{ $t('brand_manager_view_tab_banners_product_image') }}</th>
              <!-- <th></th> -->
              <th>{{ $t('brand_manager_view_tab_banners_product_region') }}</th>
              <th>{{ $t('brand_manager_view_tab_banners_product_enable') }}</th>
              <th></th>
            </tr>
          </thead>
    
          <tr v-for="item in data_banners.box_banners" :class="(item.is_enable || !item.is_enable && !show_box_active_banners) ? '' : 'hidden'">

            <td class="an">
               <div class="banner_img">
                 <img class="" :src="item.url" alt="" data-not-lazy>
               </div>
            </td>

            <!-- <td class="">
              <Chart v-if="data_banners.banners_stat['cpm_box_' + item.id]"
                  :data="data_banners.banners_stat['cpm_box_' + item.id] || []"
               />
            </td> -->

            <td class="">
              {{ (item.items_region !== null && item.items_region.length>0) ? item.items_region.map(e => e.name).join(", ") : '' }}
            </td>

            <td class="ac">
              <n-switch v-model:value="item.is_enable" :checked-value="1" :unchecked-value="0" :disabled="(!item.is_enable && !item.is_allow) || !item.id" @update:value="item.is_enable = parseInt(item.is_enable);allowCountBanner('box');enabledBanner(item,'box')"/>
            </td>

            <td class="actions"> 
              <div class="actions-btns"> 
                
                <UiButton                
                    :name="'Statistic'"
                    @click="openStatistic(item.id, 'box')"
                    />
                  

                <UiButton                
                    :tag="'NuxtLink'"
                    :to="'banners/edit/box/' + (item.id ? item.id : '0?action=edit_item&tmp_id=' + item.tmp_id)"
                    :ico="'icon-pencil'"
                    />
                  
                <UiButton
                  :type="'red'"
                  :ico="'icon-remove'"
                  @click="removeBannerConfirm(item.id || 0,item.tmp_id || 0,'box')"
                  />
                  
              </div>
            </td>

          </tr>
        
        </n-table>
        
        <div v-else>
          <UiEmpty 
            :text="'No banners'"
            />
        </div>


      </div>
      
      <div style="clear:both">&nbsp;</div>
      <div style="clear:both">&nbsp;</div>

      <div v-if="allowTypeBanner('cpm')">

        <div class="subtitle"> 
        <h2 class="banner_type_name">{{ $t('brand_manager_view_tab_banners_cpm_banner') }}</h2>

          <div class="active-switch">
            <n-switch v-model:value="show_cpm_active_banners" :checked-value="true" :unchecked-value="false" />
            &nbsp;&nbsp;{{ $t('brand_manager_view_tab_banners_only_active') }}
          </div>
          <UiButton                
            :tag="'NuxtLink'"
            :to="'banners/edit/cpm/0'"
            :name="$t('brand_manager_view_tab_banners_add_button')"
            /> 
        </div>


        <div style="clear:both">&nbsp;</div>


        <n-table 
          :single-column="false" 
          :single-line="false" 
          class="banners_table" 
          v-if="data_banners?.cpm_banners?.length"
          >
        
          <thead v-if="data_banners.cpm_banners.find(b => b.is_enable) || !show_cpm_active_banners">
            <tr>
              <th>{{ $t('brand_manager_view_tab_banners_product_image') }}</th>
              <!-- <th></th> -->
              <th>{{ $t('brand_manager_view_tab_banners_product_region') }}</th>
              <th>{{ $t('brand_manager_view_tab_banners_product_enable') }}</th>
              <th></th>
            </tr>
          </thead>
    
          <tr v-for="item in data_banners.cpm_banners" :class="(item.is_enable || !item.is_enable && !show_cpm_active_banners) ? '' : 'hidden'">

            <td class="an">
               <div class="banner_img">
                 <img class="" :src="item.url" alt="" data-not-lazy>
               </div>
            </td>

            <!-- <td class="">
              <Chart v-if="data_banners.banners_stat['cpm_' + item.id]"
                  :data="data_banners.banners_stat['cpm_' + item.id] || []"
               />
            </td> -->

            <td class="">
              {{ (item.items_region !== null && item.items_region.length>0) ? item.items_region.map(e => e.name).join(", ") : '' }}
            </td>

            <td class="ac">
              <n-switch v-model:value="item.is_enable" :checked-value="1" :unchecked-value="0" :disabled="(!item.is_enable && !item.is_allow) || !item.id" @update:value="item.is_enable = parseInt(item.is_enable);allowCountBanner('cpm');enabledBanner(item,'cpm')"/>
            </td>

            <td class="actions">
              <div class="actions-btns"> 

              <UiButton                
                  :name="'Statistic'"
                  @click="openStatistic(item.id, 'cpm')"
                  />
                  
              <UiButton                
                  :tag="'NuxtLink'"
                  :to="'banners/edit/cpm/' + (item?.id ? item.id : '0?action=edit_item&tmp_id=' + item.tmp_id)"
                  :ico="'icon-pencil'"
                  />
                
              <UiButton
                :type="'red'"
                :ico="'icon-remove'"
                @click="removeBannerConfirm(item.id || 0,item.tmp_id || 0,'cpm')"
                />
                 
  
              </div>
            </td>

          </tr>
        
        </n-table>

        <div v-else>
          <UiEmpty 
            :text="'No banners'"
            />
        </div>


      </div>
      
      <div style="clear:both">&nbsp;</div>
      <div style="clear:both">&nbsp;</div>

      <div v-if="allowTypeBanner('header')">

        <div class="subtitle"> 
        <h2 class="banner_type_name">{{ $t('brand_manager_view_tab_banners_header_banner') }}</h2>

          <div class="active-switch">
            <n-switch v-model:value="show_header_active_banners" :checked-value="true" :unchecked-value="false" />
            &nbsp;&nbsp;{{ $t('brand_manager_view_tab_banners_only_active') }}
          </div>

          <UiButton                
            :tag="'NuxtLink'"
            :to="'banners/edit/header/0'"
            :name="$t('brand_manager_view_tab_banners_add_button')"
            />  
        </div>


        <div style="clear:both">&nbsp;</div>

        <n-table 
          :single-column="false" 
          :single-line="false" 
          class="banners_table" 
          v-if="data_banners?.header_banners?.length"
          >
        
          <thead v-if="data_banners.header_banners.find(b => b.is_enable) || !show_header_active_banners">
            <tr>
              <th>{{ $t('brand_manager_view_tab_banners_product_image') }}</th>
              <!-- <th></th> -->
              <th>{{ $t('brand_manager_view_tab_banners_product_region') }}</th>
              <th>{{ $t('brand_manager_view_tab_banners_product_enable') }}</th>
              <th></th>
            </tr>
          </thead>
    
          <tr v-for="item in data_banners.header_banners" :class="(item.is_enable || !item.is_enable && !show_header_active_banners) ? '' : 'hidden'">

            <td class="an">
               <div class="banner_img">
                 <img class="" :src="item.s_back" alt="" data-not-lazy>
               </div>
            </td>

            <!-- <td class="">
              <Chart v-if="data_banners.banners_stat['header_' + item.id]"
                  :data="data_banners.banners_stat['header_' + item.id] || []"
               />
            </td> -->

            <td class="">
              {{ (item.items_region !== null && item.items_region.length>0) ? item.items_region.map(e => e.name).join(", ") : '' }}
            </td>

            <td class="ac">
              <n-switch v-model:value="item.is_enable" :checked-value="1" :unchecked-value="0" :disabled="(!item.is_enable && !item.is_allow) || !item.id" @update:value="item.is_enable = parseInt(item.is_enable);allowCountBanner('header');enabledBanner(item,'header')"/>
            </td>

            <td class="ac actions">   
              <div class="actions-btns"> 

                <UiButton                
                    :name="'Statistic'"
                    @click="openStatistic(item.id, 'header')"
                    />
                      
                <UiButton                
                    :tag="'NuxtLink'"
                    :to="'banners/edit/header/' + (item?.id ? item.id : '0?action=edit_item&tmp_id=' + item.tmp_id)"
                    :ico="'icon-pencil'"
                    />
                  
                <UiButton
                  :type="'red'"
                  :ico="'icon-remove'"
                  @click="removeBannerConfirm(item.id || 0,item.tmp_id || 0,'header')"
                  />
                  
  
              </div>
            </td>

          </tr>
        
        </n-table>

        <div v-else>
          <UiEmpty 
            :text="'No banners'"
            />
        </div>

      </div>


      <ChartModal
        v-if="showStatisticBannerId && showStatisticBannerType"
        :banner-id="showStatisticBannerId"
        :banner-type="showStatisticBannerType"
        @close="openStatistic(null,null)"
        />

      <ChartPackageModal
        v-if="showStatisticPackage"
        @close="showStatisticPackage = false"
        />



    </div>
  
</template>

<script setup>

import { NButton, NButtonGroup, NTable, useDialog, NDialogProvider, useMessage, NMessageProvider, NSwitch } from 'naive-ui'
import { useI18n } from "vue-i18n"

import Chart from '@/pages/brands-manager/components/Chart'
import ChartModal from '@/pages/brands-manager/components/ChartModal'
import ChartPackageModal from '@/pages/brands-manager/components/ChartPackageModal'
import Utils from '/pages/brands-manager/func/utils.js'

const {$api} = useNuxtApp()
const { t } = useI18n()
const dialog = useDialog()
const message = useMessage()

const is_load_first = ref(false)
const show_box_active_banners_first = ref(false)
const show_box_active_banners = ref(true)
const show_cpm_active_banners_first = ref(false)
const show_cpm_active_banners = ref(true)
const show_header_active_banners_first = ref(false)
const show_header_active_banners = ref(true)
const showStatisticBannerId = ref(null)
const showStatisticBannerType = ref(null)
const showStatisticPackage = ref(false)

const cpm_box_cnt = ref(3)
const header_cnt = ref(1)

const props = defineProps({
  data: {
    type: Object    
  }
});


const data = ref(props.data)


if((data.value.bank_info && data.value.bank_info.id==117) || useAuth().getId() == 163542){//only for FB
  cpm_box_cnt.value = 999;
  header_cnt.value = 999;
}

const openStatistic = (id, type) => {
  showStatisticBannerId.value = id;
  showStatisticBannerType.value = type;
}

const setAllow = (type) => {
  if(type == 'box'){
    for(var banner of data_banners.value.box_banners) {
      banner.is_allow = true;
    }
  }
  if(type == 'cpm'){
    for(var banner of data_banners.value.cpm_banners) {
      banner.is_allow = true;
    }
  }
  if(type == 'header'){
    for(var banner of data_banners.value.header_banners) {
      banner.is_allow = true;
    }
  }
}

const allowTypeBanner = (type) => {
  if(data_banners.value && data_banners.value.items_allow_banner_type != null && data_banners.value.items_allow_banner_type.length>0){
    for(var k of data_banners.value.items_allow_banner_type){
      if(k.name == type) return true;
    }
    return false;
  }
  
  return true;
}

const allowCountBanner = (type) => {
  var items_allow_region = data_banners.value.items_allow_region;
  if(items_allow_region == null || !items_allow_region || items_allow_region.length<=0){
    return true;
  }

  setAllow(type);

  var r = [];
  for(var region of items_allow_region) {
    r.push({'name': region.name, 'cnt': 0})
  }

  var banners = [];
  if(type == 'box') banners = data_banners.value.box_banners;
  if(type == 'cpm') banners = data_banners.value.cpm_banners;
  if(type == 'header') banners = data_banners.value.header_banners;
  for(var banner of banners) {
    if(banner.id && banner.is_enable && banner.items_region){
      var banner_regions = Object.values(banner.items_region).map(e => e.name);
      for(var region of r) {
        if(banner_regions.includes(region.name)) region.cnt++;
      }
    }
  }

  for(var region of r) {
    if((type == 'box' || type == 'cpm') && region.cnt >= cpm_box_cnt.value){
      //if(is_load_first.value) message.warning(t('brand_manager_view_tab_banners_allow_cpm_error'));
      for(var banner of banners) {
        if(banner.id && !banner.is_enable && banner.items_region){
          var banner_regions = Object.values(banner.items_region).map(e => e.name);
          if(banner_regions.includes(region.name)) banner.is_allow = false;
        }
      }
    }
    if(type == 'header' && region.cnt >= header_cnt.value){
      //if(is_load_first.value) message.warning(t('brand_manager_view_tab_banners_allow_cpm_error'));
      for(var banner of banners) {
        if(banner.id && !banner.is_enable && banner.items_region){
          var banner_regions = Object.values(banner.items_region).map(e => e.name);
          if(banner_regions.includes(region.name)) banner.is_allow = false;
        }
      }
    }
  }
}

const removeBannerConfirm = (id,tmp_id,type) => {
  dialog.error({
    title: 'Delete',
    content: t('brand_manager_view_tab_products_delete_confirm'),
    positiveText: 'Yes',
    negativeText: 'Cancel',
    transformOrigin: 'center',
    onPositiveClick: () => {
      removeBanner(id,tmp_id,type);
    },
    onNegativeClick: () => {}
  })
}

const removeBanner = (id,tmp_id,type) => {
  if(!id && !tmp_id){
    return;
  }
  
  $api.deleteB2BBanner(id,tmp_id,type).then(res => {
    loadBanners();
  }).catch(function (error) {
     message.error(t('brand_manager_view_generic_error'));
  });
}

const enabledBanner = (banner,type) => {
  if(!banner || !banner.id){
    return
  }
  var params = {};
  params.status = banner.is_enable;

  $api.postBannerEnable(banner.id,type,params).then(res => {
    message.success('Saved')
    //loadBanners();
  }).catch(function (error) {
     message.error(t('brand_manager_view_generic_error'));
  });
}



const loadBanners = async () => {

  var res = await $api.getB2BBanners();
  if(res){
    data_banners.value = res;
    is_load_first.value = false;
    allowCountBanner('box');
    allowCountBanner('cpm');
    allowCountBanner('header');
    is_load_first.value = true;
    return res;
  }

}

const { pending: is_loading,  data: data_banners } = await useLazyAsyncData('data_banners', async () => await loadBanners())


 
</script>

<style scoped>



/**/
 
</style>

<style>

.banner_type_name{
  font-size: 1.2rem!important;
  font-weight: bold;
  color: #484848!important;
  flex-shrink: 0;
}

.subtitle{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap:10px;
}
 
.active-switch{
  margin-right: auto;
}

/* banners_table */

.banners_table{

}

.banners_table >td {
  border-left: 1px solid rgba(34, 36, 38, 0.1);
}

.banners_table >td:last-child {
  border-right: 1px solid rgba(34, 36, 38, 0.1);
}

.banners_table >td{
  vertical-align: middle;
}

.banners_table .ac{
  width: 100px;
}

.banners_table .actions{
  width: 200px;
}

.banners_table .actions-btns{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.banners_table .acc{
  max-width: 50px;
}

.banners_table .al{
  max-width: 120px;
}

.banners_table .an{
  width: 150px;
}

.banners_table .banner_img img {
  width: 140px;
}

.is-right{
  float: right;
}

.white-color{
  color: white;
}

.red-color{
  color: red;
}

.green-color{
  color: green;
}
.actions-head{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

</style>